import { createApp } from 'vue'
import App from './App.vue'
import axios from 'axios'

const app = createApp(App)

app.config.globalProperties.$axios = axios.create({
  baseURL: '/api',
  timeout: 30000
})

app.mount('#app')
