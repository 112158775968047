<template>
  <div class="auth-container">
    <div class="card">
      <div class="card-body" id="card-login">
        <h1 class="fw-black mb-2">스마트부이</h1>
        <p class="fw-light text-secondary mb-4">안녕하세요!</p>
        <div class="form-login">
          <div class="mb-3">
            <label class="form-label fw-bold" for="id">ID</label>
            <input
              type="text"
              class="form-control"
              id="id"
              placeholder="ID"
              autofocus=""
              required=""
              v-model="user.id"
              @keypress.enter="processLogin"
            />
            <div class="invalid-feedback">ID</div>
          </div>
          <div class="mb-3">
            <label class="form-label fw-bold" for="password">Password</label>
            <input
              type="password"
              class="form-control"
              id="password"
              placeholder="Password"
              required=""
              v-model="user.password"
              @keypress.enter="processLogin"
            />
            <div class="invalid-feedback">Enter a password.</div>
          </div>
          <!--<div class="mb-3 d-flex justify-content-between align-items-center">
            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="remember">
              <label class="form-check-label" for="remember">로그인 유지</label>
            </div>
          </div>-->
          <button
            type="submit"
            class="btn btn-primary w-100"
            @click="processLogin"
          >
            로그인
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "App",
  data() {
    return {
      user: {
        id: "",
        password: "",
      },
    };
  },
  methods: {
    processLogin() {
      this.$axios
        .post("/v1/user/auth", this.user)
        .then(() => {
          location.href = "/dashboard";
        })
        .catch((resp) => {
          if (resp?.response?.data?.error) {
            alert(resp?.response?.data?.error);
          } else {
            alert("로그인 중 오류가 발생하였습니다.");
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#card-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.form-login {
  min-width: 432px;
}
.auth-container {
  background: url("https://photo.coolenjoy.net/data/editor/1911/Bimg_ffd6d3b5f45c62957583ec1d17c8b4ae_zoui.jpg")
    center center / cover no-repeat;
}
</style>
